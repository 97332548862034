<template>

  <div class="row h-100  justify-content-center">
    <!--:: Assistenza -->
    <div id="mybutton-a">
      <a
          href="#"
          class="mb-5 btn btn-icon w-auto d-flex btn-lg px-2"
          @click.prevent="alertHelp"
          id="pop-help"
        >
        <span class="label label-circle bg-warning  text-white p-12 text-center font-weight-bolder">
            <i class="far fa-life-ring text-white icon-2x mb-1"></i>
        </span>
       </a>
      <b-popover target="pop-plus" triggers="hover">
        <template v-slot:title>{{$t("HOME.ASSISTENZA")}}</template>
      </b-popover>
    </div>
    <!--:: Homepage -->
    <div class="col-lg-10 col-xl-10">
      <div class="d-flex flex-column">
        <div class="row align-items-center mt-5 mx-10">
          <b-tabs class="w-100">
            <b-tab active>
              <template v-slot:title>
                <div class="p-3 border-bottom border-2 border-bottom-primary">
                  {{$t("HOME.GETSTARTED")}}
                </div>
              </template>
              <b-card-text>
                <div class="row mt-5 mb-5">
                  <div class="d-flex">
                    <div class="container">
                      <div class="d-flex flex-column">

                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <!--begin::Elenco processi row-1-->
                    <div class="row">
                      <div class="d-flex flex-column mx-6">
                        <h3 class="display-4">{{$t("HOME.PROCESSIATTIVI")}}</h3>
                      </div>
                    </div>
                    <div v-if="processes" class="row mt-5">
                      <div
                        class="col-12"
                        v-for="(item, index) in processes"
                        :key="item.id"
                      >
                        <ProcessCardPreview
                          :process="item"
                          :index="index"
                        ></ProcessCardPreview>
                      </div>
                    </div>
                    
                    <div class="row align-items-center">
                      
                      <div class="col-12 mb-5">
                        <a href="/process-list" class="cursor-pointer" role="button">
                          <div class="card card-custom card-stretch border-primary-hover align-items-center">
<!--                            <div class="card-body">-->
<!--                              <div class="d-flex column">-->
<!--                                <div class="mr-10"><i class="fas fa-list icon-3x text-primary"></i></div>-->
<!--                                <div class="">-->
<!--                                  <h3 class="font-weight-bolder mb-3  text-primary">{{$t("HOME.PARTECIPA") /* #key_loc */}} </h3>-->
<!--                                  <p class="lh-md lead text-dark-75 mb-0">{{$t("HOME.PROCESSIISCRITTO") /* #key_loc */}} </p>-->
<!--                                </div>-->
<!--                              </div>-->

<!--                            </div>-->
                          </div>
                        </a>
                      </div>
                      <!--::Payoff
                      <div class="col-12 mb-5">
                        <a href="/personal-space-activities"  class="cursor-pointer" role="button">
                        <div class="card card-custom card-stretch align-items-center">
                          <div class="card-body">
                            <div class="d-flex column">
                              <div class="mr-10"><i class="fas fa-address-card icon-3x text-primary"></i></div>
                              <div class="">
                                <h3 class="font-weight-bolder mb-3  text-primary">{{$t("HOME.PROFILO")}} </h3>
                                <p class="lh-md lead text-dark-75 mb-0">{{$t("HOME.PROFILODETT")}} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        </a>
                      </div>
                      -->
                      
                    </div>

                  </div>
                </div>

              </b-card-text>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div class="p-3 border-bottom border-2 border-bottom-primary">
                  {{$t("HOME.ORGANIZZAZIONE")}}
                </div>
              </template>
              <b-card-text>
                <div class="row mt-5 mb-5">
                  <div class="d-flex mt-5">
                    <div class="container">
                      <div class="d-flex flex-column">
                        <h2 class="display-4"> {{$t("HOME.DATIGRAFICI")}}</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-5 row align-items-center">

                  <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-university icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">{{nr_organizations}} </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("HOME.ORGANIZZAZIONI")}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-user-circle icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="nr_users"> {{ nr_users }}</div>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-else> --- </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("HOME.UTENTIREG")}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-person-booth icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="nr_voters">{{nr_voters}}</div>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-else> --- </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("HOME.VOTANTI")}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-vote-yea icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="nr_votes"> {{nr_votes}}</div>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-else> --- </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("HOME.VOTIRACCOLTI")}}</a>
                      </div>
                    </div>
                  </div>

                </div>
                 <div class="row align-items-center">

                  <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-tags icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="nr_challenges">{{nr_challenges}} </div>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-else> --- </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("HOME.OBIETTIVI")}}</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-map-marked-alt icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="nr_scenarios">{{nr_scenarios}}</div>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-else> --- </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"> {{$t("HOME.SCENARI")}}</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-users icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="nr_groups">{{nr_groups}}</div>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-else> --- </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("HOME.GRUPPICOM")}}</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-3  col-lg-3 col-xl-3 mb-5">
                    <div class="card card-custom card-stretch">
                      <div class="card-body">
                        <i class="fas fa-layer-group icon-3x text-primary"></i>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-if="nr_processes">{{nr_processes}} </div>
                        <div class="text-dark font-weight-bolder font-size-h2 mt-3" v-else> --- </div>
                        <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{{$t("HOME.PROCESSIATTIVI")}}</a>
                      </div>
                    </div>
                  </div>

                </div>
              </b-card-text>
            </b-tab>

          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ProcessCardPreview from "@/view/pages/process/partials/ProcessCardPreview.vue";
// import GroupCardPreview from "@/view/pages/groups/partials/GroupCardPreview";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
//import { Carousel, Slide } from 'vue-carousel';
import Swal from "sweetalert2";

export default {
  name: "home",
  components: {
    ProcessCardPreview,
    // GroupCardPreview,
    //OrganizationMenu
    //Carousel,
    //Slide
  },
  data() {
    return {
      nr_users: 0,
      nr_processes: 0,
      nr_groups: 0,
      nr_organizations: 0,
      nr_scenarios: 0,
      nr_challenges: 0,
      nr_votes: 0,
      nr_voters: 0,
      processes: [],
      // groups: []
      orderField: "startDate",
      orderVersus: "desc",
    };
  },
  mounted() {

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: JSON.parse(window.localStorage.getItem('organization')).currentOrganization.data.name}
    ]);

    this.getUsers();
    this.getScenarios();
    this.getChallenges();
    this.getProcesses();
    this.getGroups();
    this.getOrganizations();

    // console.log("Dominio corrente: " + this.$currentDomain);
    // console.log('Current organization: ', this.getOrganization);

  },
  methods: {
    getScenarios() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('scenarios/count', false);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.nr_scenarios = res.data;
          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    },
    alertInfo(){
      Swal.fire({
        title: this.$t("HOME.ALERT1TITLE"),
        html: this.$t("HOME.ALERT1"),
        icon: "info",
        confirmButtonClass: "btn btn-primary"
      });
    },
    alertHelp(){
      Swal.fire({
        title: this.$t("HOME.ALERT2TITLE"),
        html: this.$t("HOME.ALERT2"),
        icon: "info",
        confirmButtonClass: "btn btn-primary"
      });
    },
    getChallenges() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('challenges/count', false);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            // this.nr_challenges = res.data.length;
            this.nr_challenges = res.data;
          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    },
    getProcesses() {
      return new Promise((resolve, reject) => {

        let qs = "sort=" + this.orderField + "," + this.orderVersus;

        var endpoint = this.generateUrl('processes-page', true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + qs)
          .then(res => {
            console.log("Elenco processi: ", res);

            let homeProcesses = res.data.content.slice(0, 5);
            this.processes = homeProcesses;
            //this.processes = res.data.content;
            this.nr_processes = res.data.meta.total;

            // n. processi con fasi attive (e quindi visibili in process-list)
            let countProcessWithActivePhases = res.data.content.filter(process => process.countProcessPhases > 0 && process.publiclyVisible != false).length; // todo: chiedi a Carmelo
            if(countProcessWithActivePhases == 1) {

              let resDataContent = res.data.content;
              let indexProcessWithActivePhases = resDataContent.findIndex(process => process.countProcessPhases > 0 && process.publiclyVisible != false);

              resDataContent[indexProcessWithActivePhases].countProcessPhases == 1 ? // il processo ha una sola fase?
                  resDataContent[indexProcessWithActivePhases].countProcess2Modules == 1 ? // la fase ha un solo modulo?
                      this.$router.push({ name: "processModuleIdeaList", params: { id: resDataContent[indexProcessWithActivePhases].singleProcess2ModuleId, process_id: resDataContent[indexProcessWithActivePhases].id, phase_id: resDataContent[indexProcessWithActivePhases].singleProcessPhaseId }}) : // n. moduli == 1 : vai a idea-list
                      this.$router.push({ name: "processPhase", params: { phase_id: resDataContent[indexProcessWithActivePhases].singleProcessPhaseId, process_id: resDataContent[indexProcessWithActivePhases].id }}) : // n. fasi == 1 : vai a process-phase
                  this.$router.push({ name: "processDetail", params: { id: resDataContent[indexProcessWithActivePhases].id, }}); // n. processi == 1 : vai a process-detail
            }

          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    },
    getOrganizations() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('organizations/count', false);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.nr_organizations = res.data;
          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    },
    // getGroups() {
    //   return new Promise((resolve, reject) => {
    //     ApiService.get(this.$apiResource, "groups-expanded-page/locs/it")
    //       .then(res => {
    //         // console.log(res);
    //         let homeGroups = res.data.content.slice(0, 3);
    //         this.groups = homeGroups;
    //         //this.processes = res.data.content;
    //         console.log(this.groups);
    //       })
    //       .catch(() => {
    //         // .catch(({ response }) => {
    //         //   console.log(response);
    //         // context.commit(SET_ERROR, [response.data.error]);
    //         reject(0);
    //       });
    //   });
    // },
    getGroups() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('groups/count', false);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.nr_groups = res.data;
          })
          .catch(() => {
            reject(0);
          });
      });
    },
    getUsers() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('users/count', false);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.nr_users = res.data;
          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
          });
      });
    }
  }
};
</script>
